import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { BaseSection } from 'components/Section';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeBrandedColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeFunctionalColor,
  getThemeGutter,
  getThemeInputBorderShape,
  getThemeInputFontSizeShape,
  getThemeInputHeightShape,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  getThemeNeutralColor,
  MAIN_COLOR,
} from 'theme';

export const StyledJobApplicationFormContainer = styled(BaseSection)(
  (props) => ({
    paddingTop: getThemeGutter(props, TSHIRT_SIZE.M),
    paddingBottom: getThemeGutter(props, TSHIRT_SIZE.M),
    overflow: 'initial',

    form: {
      width: '70%',
      [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
        width: '100%',
      },
    },
  })
);

export const StyledForm = styled.form((props) => ({
  width: '100%',

  [`${StyledFormSection}+${StyledFormSection}`]: {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
  },
}));

export const StyledFormSection = styled.div((props) => ({
  [`${StyledFormSectionTitle} + div`]: {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  [`${StyledFormGroup}+${StyledFormGroup}`]: {
    marginTop: 32,
  },
  [`${StyledFormGroup}+${StyledFileUploadGroup}`]: {
    marginTop: 32,
  },
}));

export const StyledFormGroup = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  gap: getThemeGutter(props, TSHIRT_SIZE.S),

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gap: getThemeGutter(props, TSHIRT_SIZE.XS),
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },

  p: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    fontWeight: 500,
    lineHeight: 1,
    '& + small': {
      fontSize: getThemeFontSize(props, TEXT_TYPE.SMALL),
    },
  },
  '> label': {
    minWidth: '204px',

    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      alignSelf: CSS_ALIGN.FLEX_START,
    },
  },
  '> div': {
    flexGrow: 1,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      width: '100%',
    },
  },
}));

export const StyledSmallFormGroup = styled.div({
  marginTop: 32,
  width: 'max-content',
});

export const StyledFormSectionTitle = styled.h3((props) => ({
  fontWeight: 500,
  fontSize: getThemeFontSize(props, TEXT_TYPE.H3),
  lineHeight: '36px',
}));

export const StyledErrorText = styled.small((props) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
  marginTop: 6,
}));

export const StyledTextareaWrapper = styled.div<{ error?: string }>(
  ({ error, ...props }) => ({
    position: 'relative',
    boxSizing: 'border-box',
    border: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.LIGHT)}`,
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    height: '140px',
    display: 'flex',
    flexDirection: 'column',
    ...(!!error && {
      borderColor: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    }),

    textarea: {
      flexGrow: 1,
    },
    div: {
      flexShrink: 0,
    },
  })
);

export const StyledTextarea = styled.textarea((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  border: 0,
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  resize: 'none',
  padding: `9px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.L)}`,

  '&::placeholder': {
    color: getThemeNeutralColor(props, COLOR_SHADE.LIGHT),
  },
}));

export const StyledTextareaFooter = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${getThemeInputPaddingShape(
    props,
    TSHIRT_SIZE.S
  )} ${getThemeInputPaddingShape(props, TSHIRT_SIZE.L)}`,
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  color: getThemeNeutralColor(props, COLOR_SHADE.LIGHT),

  'span + span': {
    transform: 'scale(0)',
    animation: `${scaleInKeyframes} 0.3s ease-in forwards`,
    transformOrigin: 'center',
  },

  small: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
  },
}));

const scaleInKeyframes = keyframes({
  from: {
    transform: 'scale(0)',
  },
  to: {
    transform: 'scale(1)',
  },
});

export const StyledFileUploadGroup = StyledFormGroup.withComponent('div');

export const StyledFileUploadWrapper = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
  gap: `4px ${getThemeGutter(props, TSHIRT_SIZE.S)}`,
  flexWrap: CSS_FLEX_WRAP.WRAP,
  position: CSS_POSITION.RELATIVE,
  overflowX: 'clip',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    '> div': {
      width: '100%',
    },
  },
}));

export const StyledConsentField = styled.label((props) => ({
  display: CSS_DISPLAY.FLEX,
  gap: getThemeGutter(props, TSHIRT_SIZE.XS),
  alignItems: CSS_ALIGN.CENTER,
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  small: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    color: getThemeNeutralColor(props, COLOR_SHADE.SECONDARY),
    fontFamily: 'inherit',
  },
  a: {
    color: getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
    fontFamily: 'inherit',
  },
}));

const shadowPulse = keyframes({
  from: {
    background: '#ffffff4c',
    boxShadow: '-12px 0 #ffffff, 12px 0 #ffffff4c',
  },
  '50%': {
    background: '#ffffff',
    boxShadow: '-12px 0 #ffffff4c, 12px 0 #ffffff4c',
  },
  to: {
    background: '#ffffff4c',
    boxShadow: '-12px 0 #ffffff4c, 12px 0 #ffffff',
  },
});

export const StyledSubmitBtnWrapper = styled.div((props) => ({
  paddingTop: getThemeGutter(props, TSHIRT_SIZE.S),
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  gap: getThemeGutter(props, TSHIRT_SIZE.S),
  position: CSS_POSITION.RELATIVE,

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    paddingTop: getThemeGutter(props, TSHIRT_SIZE.M),
    flexDirection: CSS_FLEX_DIRECTION.COLUMN_REVERSE,
    alignItems: CSS_ALIGN.FLEX_START,
    gap: getThemeGutter(props, TSHIRT_SIZE.XS),
  },

  small: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),

    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      position: CSS_POSITION.ABSOLUTE,
      top: '4px',
    },
  },
}));

export const StyledSubmitBtn = styled.button<{
  loading?: boolean;
  shouldShowMessage?: boolean;
}>(({ loading, shouldShowMessage, ...props }) => ({
  color: getThemeMainColor(props, MAIN_COLOR.WHITE),
  border: 'none',
  backgroundColor: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
  fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.XL),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
  fontWeight: 500,
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  height: getThemeInputHeightShape(props, TSHIRT_SIZE.L),
  cursor: 'pointer',
  minWidth: '282px',
  display: CSS_DISPLAY.FLEX,
  ...(loading && { alignItems: CSS_ALIGN.CENTER }),
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  position: 'relative',
  overflow: 'hidden',

  '&::before': {
    ...(loading && { content: '""' }),
    width: '8px',
    height: '8px',
    borderRadius: '4px',
    background: '#ffffff',
    boxShadow: '-12px 0 #ffffff4c, 12px 0 #ffffff4c',
    boxSizing: 'border-box',
    animation: `${shadowPulse} 1s linear infinite alternate`,
  },
  '&::after': {
    ...(shouldShowMessage && !loading && { content: '""' }),
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    animation: `${sizePulse} 1s ease-out alternate`,
    background: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    opacity: 0.2,
    zIndex: -1,
  },
  '&:hover::after, &:focus::after': {
    visibility: 'hidden',
  },

  '& > span': {
    transition: '0.3s ease-in-out',
    ...(loading && { display: CSS_DISPLAY.NONE }),
    ...(shouldShowMessage && {
      transform: 'translateY(-50%)',
    }),
  },

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },

  '&:hover': {
    backgroundColor: `${getThemeBrandedColor(props, COLOR_SHADE.PRIMARY)}e6`,
  },
  '&:disabled': {
    cursor: 'not-allowed',
    backgroundColor: `${getThemeBrandedColor(props, COLOR_SHADE.PRIMARY)}e6`,
  },
}));

const sizePulse = keyframes`
  0% {
    transform: translate(-50%, -50%);
    border-radius: 6px;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.05, 1.25);
    border-radius: 10px;
  }
  100% {
    transform: translate(-50%, -50%);
    border-radius: 6px;
  }
`;

export const StyledButtonTextContainer = styled.span((props) => ({
  height: '100px',
  display: 'flex',
  flexDirection: 'column',

  span: {
    height: '50%',
    padding: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const StyledButtonMessage = styled.span({});

const errorIconKeyframes = keyframes({
  from: {
    transform: 'scale(0)',
    opacity: '0',
  },
  '50%': {
    opacity: '1',
  },
  to: {
    transform: 'scale(1)',
  },
});

export const StyledInputContainer = styled.div((props) => ({
  position: CSS_POSITION.RELATIVE,

  'div svg': {
    transformOrigin: 'center',
    animation: `0.2s ease-in-out ${errorIconKeyframes}`,
  },
  small: {
    position: 'absolute',
    top: '100%',
    left: 0,
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
  },
}));

export const StyledInput = styled.input<{ hasError?: boolean }>(
  ({ hasError, ...props }) => ({
    border: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.LIGHT)}`,
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    height: getThemeInputHeightShape(props, TSHIRT_SIZE.M),
    width: '100%',
    padding: `9px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.L)}`,
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    ...(hasError && {
      transition: '0.3s ease-in-out',
      borderColor: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    }),
  })
);
